import {required} from 'vuelidate/lib/validators'
import { mapGetters} from 'vuex';

const _ = require('lodash');

export default {
    props: ['popupType', 'selectedItem'],
    data() {
        return {
            form: {
                title: '',
                description: '',
                type: 0,
                order_status_id: null,
            },
            statusesList:null,
            ua: [
                '!','1','2','3','4','5','6','7','8','9','0','-','"',"'",
                'й','ц','у','к','е','н','г','ш','щ','з','х','ї',
                'ф','і','в','а','п','р','о','л','д','ж','є',
                'я','ч','с','м','и','ы','т','ь','б','ю',' ',',','.'
            ],
            lat: [
                '!','1','2','3','4','5','6','7','8','9','0','-','"',"'",
                'j','c','u','k','e','n','g','sh','shj','z','h','yi',
                'f','i','v','a','p','r','o','l','d','jg','je',
                'ya','ch','s','m','u','u','t','j','b','yu',' ',',','.'
            ]
        }
    },

    validations: {
        form: {
            title: {
                required
            }
        }
    },
    computed: {
        ...mapGetters({
            smsTemplate: 'templates/smsTemplate',
            orderStatusList: 'orders/orderStatusList'
        }),
    },
    watch:{
        smsTemplate(e){
            if(e){
                this.form = e;
            }else{
                this.form = {
                    title: '',
                    description: ''
                }
            }
        }
    },
    created() {
        this.statusesList = _.clone(this.orderStatusList.data)
        this.statusesList.unshift({
                id:null,
                title: 'Без статусу'
            }
        )
    },
    methods: {
        translateKir(){
          this.form.type = 1
            const toString = this.form.text.split('');
            let text = [];
            toString.map(item =>{
                item = item.toLowerCase();
                const letPos = this.lat.indexOf(item)
                text.push(this.ua[letPos]);
            })
            this.form.text = text.join('')
            if(this.form.text.length >= 70){
                this.form.text = this.form.text.slice(0,70);
            }
        },
        translateLat(){
          this.form.type = 0
            const toString = this.form.text.split('');
            let text = [];
            toString.map(item =>{
                item = item.toLowerCase();
                const letPos = this.ua.indexOf(item)
                text.push(this.lat[letPos]);
            })
            this.form.text = text.join('');
            if(this.form.text.length >= 160){
                this.form.text = this.form.text.slice(0,160);
            }
        },
        validateLength(length){
            if(this.form.text.length >= length){
                this.form.text = this.form.text.slice(0,length);
            }
        },
        closePopup() {
            this.$emit('closePopup')
        },
        sendSmsTemplate() {
            if(this.form.id){
                this.$emit('changeSmsTemplate', this.form)
            }else{
                this.$emit('sendSmsTemplate', this.form)
            }
        },
    },
}
